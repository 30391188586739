<template>
<v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `px-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
    <v-card-title class="px-0 break-word">
        Авторизація :: get-auth-token
    </v-card-title>
    <v-card-text class="px-0">
        <p>Метод дозволяє отримати Токен авторизації <b class="primary--text">authToken</b>, який є обов'язковим в більшості запитів API.</p>
        <p>Для отримання <b class="primary--text">authToken</b> необхідно мати пару ключів: відкритий (публічний) ключ користувача <b class="primary--text">user_key</b> та приватний ключ <b class="primary--text">secret</b>.</p>
        <p>Для отримання або зміни ключів перейдіть у <a @click="$router.push('/api-settings')">Налаштування API</a>.</p>
    </v-card-text>
    <v-card-text class="pa-4 pa-md-6 pb-3 blue lighten-5">
        <p><b>Метод:</b> <b class="primary--text">get-auth-token</b>, моделі <b class="primary--text">auth</b></p>
        <p><b>URL:</b> POST https://auth.yugcontract.ua/api/auth/get-auth-token</p>
        <p><b>Формат:</b> <b class="primary--text">JSON</b></p>
        <p><b>Доступність:</b> За умови звернення з дозволеної <a @click="$router.push('/api-settings')">IP адреси</a>.</p>
    </v-card-text>
    <v-card-title class="px-0 break-word mt-4">
        Параметри запиту
    </v-card-title>
    <v-card-text class="px-0">
        <v-row no-gutters>
            <v-col cols="12" md="7" class="py-0">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left" width="25%">
                                Параметр запиту
                            </th>
                            <th class="text-left" width="20%">
                                Тип даних
                            </th>
                            <th class="text-left" width="55%">
                                Опис
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="font-weight-bold primary--text"> requestToken<v-icon color="primary" class="mb-3">*</v-icon>
                            </td>
                            <td>String</td>
                            <td>формується за допомогою user_key та secret</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-card-text>
    <v-card-title class="px-0 break-word">
        Заголовки HTTP
    </v-card-title>
    <v-card-text class="px-0">
        <v-row no-gutters>
            <v-col cols="12" md="7" class="py-0 mb-6">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left" width="25%">
                                Заголовок
                            </th>
                            <th class="text-left" width="75%">
                                Значення
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="font-weight-bold primary--text">Content-Type<v-icon color="primary" class="mb-3">*</v-icon>
                            </td>
                            <td>application/json</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-card-text>
    <v-card-text class="px-0">
        <p>Для цифрового підпису використовуються веб-токени JSON <b class="primary--text">JWT</b> - це відкритий галузевий стандарт <b>RFC 7519</b> для надійного представлення вимог, що передаються між двома сторонами. Электронний підпис дає 100% безпеку використання API, та гарантує, що зловмистник не зможе отримати доступ до інформації користувача.</p>
        <p>Для ознайомлення з технологією <b class="primary--text">JWT</b> можете скористатися сайтом <a href="https://jwt.io" target="_blank">JWT.IO</a>, який дозволяє декодувати, перевіряти та генерувати JWT.</p>
        <p>Для отримання Токену авторизації <b class="primary--text">authToken</b> потрібно створити <b class="primary--text">requestToken</b>, підписавши публічний ключ користувача <b class="primary--text">user_key</b> приватним ключем <b class="primary--text">secret</b>.</p>
        <p>Увага! Для цифрового підпису треба використовувати алгорітм <b class="primary--text">HS256</b></p>
        <div class="body-2">Приклад створення <b class="primary--text">requestToken</b> за допомогою npm модуля <a target="_blank" href="https://www.npmjs.com/package/jsonwebtoken">jsonwebtoken</a>:</div>
        <v-row no-gutters>
            <v-col cols="12" md="7" class="py-0">
                <v-row no-gutters>
                    <v-col cols="12" sm="12" lg="12" class="d-flex align-top">
                        <v-textarea rows="4" dark background-color="grey darken-2" :value="createRT" readonly class="code"></v-textarea>
                        <v-btn icon @click="clickHandler(7)" title="Копіювати в буфер обміну" v-clipboard="() => createRT" v-clipboard:success="clipboardSuccessHandler">
                            <v-icon>{{copied[7] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <p>Далі, потрібно відправити отриманий <b class="primary--text">requestToken</b> за посиланням: <b>POST https://auth.yugcontract.ua/api/auth/get-auth-token</b></p>
        <p class="primary--text"><b>Зверніть увагу!</b> Термін дії наданого <b>authToken</b> обмежено 1 годиною!</p>

    </v-card-text>
    <v-card-title class="px-0 break-word">
        Приклади реалізації
    </v-card-title>
    <v-row no-gutters>
        <v-col cols="12" md="7" class="py-0">
            <h4>NodeJS</h4>
            <v-row no-gutters>
                <v-col cols="12" sm="12" lg="12" class="d-flex align-top">
                    <v-textarea dark background-color="grey darken-2" :value="codetext" rows="10" readonly class="code"></v-textarea>
                    <v-btn icon @click="clickHandler(3)" title="Копіювати в буфер обміну" v-clipboard="() => codetext" v-clipboard:success="clipboardSuccessHandler">
                        <v-icon>{{copied[3] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col cols="12" md="7" class="py-0">
            <h4>PHP</h4>
            <v-row no-gutters>
                <v-col cols="12" sm="12" lg="12" class="d-flex align-top">
                    <v-textarea dark background-color="grey darken-2" :value="codetextPhp" rows="10" readonly class="code"></v-textarea>
                    <v-btn icon @click="clickHandler(7)" title="Копіювати в буфер обміну" v-clipboard="() => codetextPhp" v-clipboard:success="clipboardSuccessHandler">
                        <v-icon>{{copied[7] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-card-title class="px-0 break-word">
        Перевірити роботу методу
    </v-card-title>
    <v-card-text class="px-0">
        Для перевірки методу заповніть <b>Параметри запиту</b>, та натисніть <b class="primary--text">Надіслати</b>.
    </v-card-text>
    <h4>Параметри запиту</h4>
    <v-row no-gutters>
        <v-col cols="12" md="7" class="py-0">
            <v-row no-gutters>
                <v-col cols="12" sm="12" lg="12" class="d-flex align-topr">
                    <v-textarea no-resize rows="2" v-model="requestToken" label="requestToken" type="text" class="code"></v-textarea>
                    <v-btn icon @click="clickHandler(4)" title="Копіювати в буфер обміну" v-clipboard="() => requestToken" v-clipboard:success="clipboardSuccessHandler">
                        <v-icon>{{copied[4] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                    <v-btn @click="createRequestToken()" color="primary">Створити</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-10">
                <v-col cols="12" sm="12" lg="12" class="d-flex align-top">
                    <v-textarea no-resize rows="9" outlined :value="requestTxt" label="HTTP Запит" type="text" readonly class="code"></v-textarea>
                    <v-btn icon @click="clickHandler(5)" title="Копіювати в буфер обміну" v-clipboard="() => requestTxt" v-clipboard:success="clipboardSuccessHandler">
                        <v-icon>{{copied[5] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                    </v-btn>

                </v-col>
                <v-col cols="12" sm="8" lg="6" class="d-flex align-center">
                    <v-btn @click="postMethod()" color="primary" :disabled="requestToken ? false : true">Надіслати</v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-10">
                <v-col cols="12" sm="12" lg="12" class="d-flex align-top">
                    <v-textarea no-resize rows="9" outlined :value="response" label="HTTP Відповідь" readonly type="text" class="code"></v-textarea>
                    <v-btn icon @click="clickHandler(6)" title="Копіювати в буфер обміну" v-clipboard="() => response" v-clipboard:success="clipboardSuccessHandler">
                        <v-icon>{{copied[6] ? 'mdi-check':'mdi-content-copy'}}</v-icon>
                    </v-btn>

                </v-col>
            </v-row>
        </v-col>
    </v-row>

</v-card>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'

import jwt from 'jsonwebtoken'

export default {
    components: {},
    directives: {},
    data: () => ({
        requestToken: '',
        methodUrl: '',
        show1: true,
        response: '',
        copied: {},
        request: `POST https://auth.yugcontract.ua/api/auth/get-auth-token HTTP/1.0
Content-Type: application/json

{
"requestToken": "{{requestToken}}"
}
`,
        createRT: `const jwt = require('jsonwebtoken');
const requestToken = jwt.sign({user_key}, 
    secret, {
    algorithm: 'HS256',
    expiresIn: 3 * 60 // 3 min
});
`,
        codeTxtPhp: `### Втрановити composer з https://getcomposer.org/download/
### Створити файл composer.json додати в нього
/*
json
{
    "name": "vendor_name/auth",
    "description": "description",
    "require": {
      "firebase/php-jwt": "^5.2",
      "ext-json": "*"
    }
}
*/

#### В коренi виконати команду 'composer install'

#### Створити файл 'exemple.php' та пiдключити бiблiотеки

<?php
require __DIR__ . '/vendor/autoload.php';
use \\Firebase\\JWT\\JWT;

try {
echo "Authorization... \\n\\r";

$auth = new Auth(); // Initialize class Auth
$result = $auth->getAuthToken(); // Send auth query in API
$authToken = $result->content->authToken; // Take token

echo "authToken: $authToken \\n\\r";

} catch (Exception $exception) {
    $message = $exception->getMessage();
    echo "Authorization Error! $message \\n\\r";
}

class Auth {
    private $secret = '{{secret}}'; //  Write secret key hear
    private $user_key = '{{user_key}}'; // Write user key hear
    protected $auth_url = 'https://auth.yugcontract.ua/api/auth/get-auth-token'; // Write link to auth API

    function getAuthToken() {

        $array_query = [
            'user_key' => $this->user_key,
            'iat' => time(),
            'exp' => time() + 3 * 60
        ];

        $requestToken = JWT::encode($array_query, $this->secret); // Generation token

        $json = json_encode(['requestToken' => $requestToken]); // Array to json

        // Set all params from API
        $options = array(
            'http' => array(
                'method'  => 'POST',
                'content' => $json,
                'header'=>  "Content-Type: application/json"
            )
        );

        // Send query in API
        $context  = stream_context_create( $options );
        $result = file_get_contents( $this->auth_url, false, $context );

        // Send result
        return json_decode( $result );
    }
}
        `,
        codeTxt: `// NodeJS get-auth-token example        
//-----------------------------------------------------------------------
// Installing
// $ npm init
// $ npm install axios jsonwebtoken 
//

// Required packages
const axios = require('axios');
const jwt = require('jsonwebtoken')
//-----------------------------------------------------------------------
//User Variables
const secret       = '{{secret}}';
const user_key     = '{{user_key}}';
//-----------------------------------------------------------------------
let authToken = "";

const authURL = 'https://auth.yugcontract.ua/api/auth/get-auth-token';
//-----------------------------------------------------------------------
(async () => {
    // ---- GET authorization token
    async function getAuthToken(){
        const requestToken = jwt.sign({
            algorithm: 'HS256',
            user_key
            }, 
            secret, {
            expiresIn: 3 * 60 // 3 min
        });
        return axios({
            method: 'POST',
            url: authURL,
            data: {
                requestToken
            }
        });
    }
    try {
        console.log('Authorization...')
        const res = await getAuthToken()
        authToken = res.data.content.authToken
        console.log('authToken: ' + authToken)
    } catch (err) {
        console.log(\`Authorization Error! \${err}\`)
        process.exit(0)
    }
})();
`,
    }),
    props: {},
    methods: {
        ...mapActions(['setSnackbar', 'touch', 'createSecret', 'getSecretPare', 'authAPIRequest']),
        ...mapGetters(['catalogTree']),
        clickHandler(v) {
            this.$set(this.copied, v, true)
            setTimeout(() => {
                this.copied[v] = false
            }, 1000);
        },
        clipboardSuccessHandler() {
            this.setSnackbar(['success', 'Скопійовано в буфер обміну'])
        },
        createRequestToken() {
            const user_key = this.secretPare.user_key
            try {
                this.requestToken = jwt.sign({
                    algorithm: 'HS256',
                    user_key
                }, this.secretPare.secret, {
                    expiresIn: 1 * 60 * 60
                })
            } catch (error) {
                console.log(error)
            }
        },
        postMethod() {
            const requestToken = this.requestToken
            this.authAPIRequest({
                    requestToken
                })
                .then(res => {
                    this.setSnackbar(['success', '200 ОК'])
                    this.response = JSON.stringify(res, null, 4)
                })
                .catch(error => {
                    console.log(error)
                    this.setSnackbar(['error', error])
                    this.response = JSON.stringify(error.message, null, 4)
                })
        }
    },
    computed: {
        secretPare() {
            return this.$store.state.main.secretPare
        },
        codetext() {
            let code = this.codeTxt
            code = code.replace(/\{\{secret\}\}/gi, (this.show1 ? this.secretPare.secret : '***********************************'))
            code = code.replace(/\{\{user_key\}\}/gi, this.secretPare.user_key)
            return code
        },
        codetextPhp() {
            let code = this.codeTxtPhp
            code = code.replace(/\{\{secret\}\}/gi, (this.show1 ? this.secretPare.secret : '***********************************'))
            code = code.replace(/\{\{user_key\}\}/gi, this.secretPare.user_key)
            return code
        },
        requestTxt() {
            let code = this.request
            code = code.replace(/\{\{requestToken\}\}/gi, this.requestToken)
            return code
        }
    },
    created() {
        this.touch()
            .then(() => {
                this.getSecretPare()
                setTimeout(() => {
                    this.$store.commit('setLoading', false)
                }, 1000);
            })
    }
}
</script>

<style>
code {
    font-family: Consolas, "courier new";
    color: crimson;
    background-color: #f1f1f1;
    padding: 2px;
    font-size: 105%;
}
</style>
